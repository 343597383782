import 'slick-carousel';

const quoteSlider = () => {
	// Selectors
	const quoteSliderModules = document.querySelectorAll( '.quote-slider-slides' );

	// Functions
	const initQuoteSlider = ( quoteSlider ) => {
		$( quoteSlider ).slick( {
			autoplay: true,
			autoplaySpeed: 5000,
			arrows: true,
			prevArrow: $( quoteSlider ).siblings( '.bottom-nav-arrows' ).find( '.prev' ),
			nextArrow: $( quoteSlider ).siblings( '.bottom-nav-arrows' ).find( '.next' ),
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: false,
			mobileFirst: true,
		} );
	};

	quoteSliderModules.forEach( quoteSlider => { initQuoteSlider( quoteSlider ) } );
};

export default quoteSlider;
