import 'slick-carousel';

const logoSlider = () => {
	// Selectors
	const logoSliderModules = document.querySelectorAll( '.logo-slider-slides' );

	// Functions
	const initLogoSlider = ( logoSlider ) => {
		$( logoSlider ).slick( {
			autoplay: true,
			autoplaySpeed: 0,
			speed: 2000,
			pauseOnHover: false,
			arrows: false,
			dots: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			centerMode: false,
			mobileFirst: true,
			cssEase: 'linear',
			responsive: [
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1000,
					settings: {
						slidesToShow: 6,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 8,
					}
				},
			]
		} );

		/*const slides = logoSlider.querySelectorAll( '.logo-slider-slide' );

		if ( window.outerWidth > 999 && slides.length < 6 ) {
			logoSlider.querySelector( '.logo-slider-slides' ).style.width = '100%';
		}

		window.addEventListener( 'resize', function() {
			if ( sliderLogos.destroy !== null ) {
				sliderLogos.destroy();
				initLogoSlider( logoSlider );
			}
		} );*/
	};

	logoSliderModules.forEach( logoSlider => { initLogoSlider( logoSlider ) } );
};

export default logoSlider;
