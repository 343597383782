import scrollIntoView from 'scroll-into-view';
import ScrollReveal from 'scrollreveal';
import '../../../node_modules/waypoints/lib/noframework.waypoints';
import Hammer from 'hammerjs';
import { throttle } from 'lodash';
import isTouchDevice from 'is-touch-device';

const screens = () => {
	// Elements
	const body = document.getElementsByTagName( 'body' )[ '0' ];
	const screensWrapper = document.querySelector( '.screens' );
	const screens = document.querySelectorAll( '.screens .screens-screen' );
	const hammer = screensWrapper ? new Hammer( screensWrapper ) : null;

	// Selectors
	const scrollRevealElements = '.scroll-reveal';

	const skipToScreenHandler = ( event ) => {
		if ( ! body.classList.contains( 'is-animating' ) ) {
			const previousScreen = document.getElementById( `${screensWrapper.getAttribute( 'data-previous-screen' )}` );
			const nextScreen = document.getElementById( `${screensWrapper.getAttribute( 'data-next-screen' )}` );

			// If scroll right or down
			if ( event.deltaY > 0 || event.deltaX > 0 ) {
				if ( nextScreen ) {
					body.classList.add( 'is-animating' );
					scrollIntoView( nextScreen, {
						time: 1000,
						align: {
							top: 0,
							left: 0,
						},
					}, () => { body.classList.remove( 'is-animating' ) } );
				}
			// If scroll left or up
			} else if ( event.deltaY < 0 || event.deltaX < 0 ) {
				if ( previousScreen ) {
					body.classList.add( 'is-animating' );
					scrollIntoView( previousScreen, {
						time: 1000,
						align: {
							top: 0,
							left: 0,
						},
					}, () => { body.classList.remove( 'is-animating' ) } );
				}
			}
		}
	};

	const throttledSkipToScreenHandler = throttle( skipToScreenHandler, 200, { leading: false, trailing: false } );

	const skipToPreviousScreenHandler = () => {
		const previousScreen = document.getElementById( `${screensWrapper.getAttribute( 'data-previous-screen' )}` );
		if ( previousScreen ) {
			body.classList.add( 'is-animating' );
			scrollIntoView( previousScreen, {
				time: 500,
				align: {
					top: 0,
					left: 0,
				},
			}, () => { body.classList.remove( 'is-animating' ) } );
		}
	};

	const skipToNextScreenHandler = () => {
		const nextScreen = document.getElementById( `${screensWrapper.getAttribute( 'data-next-screen' )}` );
		if ( nextScreen ) {
			body.classList.add( 'is-animating' );
			scrollIntoView( nextScreen, {
				time: 500,
				align: {
					top: 0,
					left: 0,
				},
			}, () => { body.classList.remove( 'is-animating' ) } );
		}
	};

	const skipToScreen = () => {
		window.addEventListener( 'wheel', throttledSkipToScreenHandler, false );
		if ( isTouchDevice() ) {
			hammer.on( 'swiperight', skipToPreviousScreenHandler );
			hammer.on( 'swipeleft', skipToNextScreenHandler );
		}
	};

	const removeSkipToScreen = () => {
		window.removeEventListener( 'wheel', throttledSkipToScreenHandler, false );
	};

	const activateAnchors = () => {
		const anchors = document.querySelectorAll( '.bottom-nav ul li a' );
		if ( anchors ) {
			anchors.forEach( ( anchor ) => {
				anchor.addEventListener( 'click', ( event ) => {
					event.preventDefault();
					body.classList.add( 'is-animating' );
					const target = document.getElementById( anchor.href.split( '#', 2 )[ 1 ] );
					if ( target ) {
						scrollIntoView( target, {
							time: 1000,
							align: {
								top: 0,
								left: 0,
							},
						}, () => { body.classList.remove( 'is-animating' ) } );
					}
				} );
			} );
		}
	};

	const activateArrows = () => {
		const arrows = document.querySelectorAll( '.bottom-nav-arrows ul li a' );
		if ( arrows ) {
			arrows.forEach( ( arrow ) => {
				arrow.addEventListener( 'click', ( event ) => {
					event.preventDefault();
					body.classList.add( 'is-animating' );
					if ( '#undefined' === arrow.href ) {
						return;
					}
					const target = document.getElementById( arrow.href.split( '#', 2 )[ 1 ] );
					if ( target ) {
						scrollIntoView( target, {
							time: 1000,
							align: {
								top: 0,
								left: 0,
							},
						}, () => { body.classList.remove( 'is-animating' ) } );
					}
				} );
			} );
		}
	};

	const checkSlidesInView = () => {
		const classes = [];
		screens.forEach( ( screen ) => {
			classes.push( screen.id );
		} );
		const anchors = document.querySelectorAll( '.bottom-nav ul li a' );
		const arrowPrev = document.querySelector( '.bottom-nav-arrows ul li.prev a' );
		const arrowNext = document.querySelector( '.bottom-nav-arrows ul li.next a' );

		// Add first class
		body.classList.add( classes[ 0 ] );
		screensWrapper.setAttribute( 'data-previous-screen', null );
		screensWrapper.setAttribute( 'data-current-screen', classes[ 0 ] );
		screensWrapper.setAttribute( 'data-next-screen', classes[ 1 ] );

		// Set arrow hrefs
		screens.forEach( ( screen ) => {
			const waypoint_forward = new Waypoint( {
				element: screen,
				horizontal: true,
				context: screensWrapper,
				offset: screen.clientWidth / 2,
				handler: function ( direction ) {
					const index = classes.indexOf( screen.id );
					// Set arrow hrefs
					if ( 'right' === direction ) {
						if ( arrowPrev && arrowNext ) {
							arrowPrev.href = '#' + classes[ index - 1 ];
							arrowNext.href = '#' + classes[ index + 1 ];
						}
					}
				}
			} );

			const waypoint_back = new Waypoint( {
				element: screen,
				horizontal: true,
				context: screensWrapper,
				offset: -screen.clientWidth / 2,
				handler: function ( direction ) {
					const index = classes.indexOf( screen.id );
					// Set arrow hrefs
					if ( 'left' === direction ) {
						if ( arrowPrev && arrowNext ) {
							arrowPrev.href = '#' + classes[ index - 1 ];
							arrowNext.href = '#' + classes[ index + 1 ];
						}
					}
				}
			} );
		} );

		// Change body classes on scroll through screens
		screens.forEach( ( screen ) => {
			const waypoint = new Waypoint( {
				element: screen,
				horizontal: true,
				context: screensWrapper,
				offset: screen.clientWidth / 2,
				handler: function ( direction ) {
					const index = classes.indexOf( screen.id );

					if ( 'right' === direction ) {
						classes.forEach( ( classname ) => {
							body.classList.remove( classname );
						} );
						body.classList.add( classes[ index ] );
						screensWrapper.setAttribute( 'data-previous-screen', classes[ index - 1 ] );
						screensWrapper.setAttribute( 'data-current-screen', classes[ index ] );
						screensWrapper.setAttribute( 'data-next-screen', classes[ index + 1 ] );

						anchors.forEach( ( anchor ) => {
							anchor.parentElement.classList.remove( 'current' );
							if ( anchor.parentElement.classList.contains( classes[ index ] ) ) {
								anchor.parentElement.classList.add( 'current' );
							}
						} );
					} else {
						classes.forEach( ( classname ) => {
							body.classList.remove( classname );
						} );
						body.classList.add( classes[ index - 1 ] );
						screensWrapper.setAttribute( 'data-previous-screen', classes[ index - 2 ] );
						screensWrapper.setAttribute( 'data-current-screen', classes[ index - 1 ] );
						screensWrapper.setAttribute( 'data-next-screen', classes[ index ] );

						anchors.forEach( ( anchor ) => {
							anchor.parentElement.classList.remove( 'current' );
							if ( anchor.parentElement.classList.contains( classes[ index - 1 ] ) ) {
								anchor.parentElement.classList.add( 'current' );
							}
						} );
					}
				}
			} );
		} );
	};

	// Check we're on the about page and there are screens
	if ( body.classList.contains( 'page-template-template-about' ) && screensWrapper ) {
		skipToScreen();
		activateAnchors();
		activateArrows();
		ScrollReveal().reveal( scrollRevealElements, {
			delay: 500,
			container: screensWrapper,
			cleanup: true,
			distance: '10%',
			duration: 1000,
			scale: 1,
			mobile: false,
		} );
		checkSlidesInView();
	} else {
		removeSkipToScreen();
	}
};

export default screens;
