import { tns } from 'tiny-slider/src/tiny-slider.module';

const splash = () => {
	// Selectors
	const splashModules = document.querySelectorAll( '.splash' );

	// Functions
	const initSplash = ( splash ) => {
		var slider = tns( {
			autoplay: true,
			autoplayButtonOutput: false,
			autoplayPosition: 'bottom',
			autoplayTimeout: 7000,
			container: splash.querySelector( '.splash-slides' ),
			controls: false,
			items: 1,
			mode: 'gallery',
			nav: true,
			navContainer: splash.querySelector( '.splash-dots' ),
			speed: 1000,
			animateIn: 'fadeInDown',
			animateOut: 'fadeOutDown',
			animateDelay: 500,
			other: {}
		} );
	};

	splashModules.forEach( splash => { initSplash( splash ) } );
};

export default splash;