const banner = () => {
	// Selectors
	const bannerUnderHeader = document.querySelectorAll( '.banner.banner-under-header' );
	const body = document.getElementsByTagName( 'body' )[0];

	if ( bannerUnderHeader.length > 0 ) {
		body.classList.add( 'banner-under-header' );
	}
};

export default banner;
