const searchForm = () => {
	// Selectors
	const body = document.getElementsByTagName( 'body' )[ 0 ];
	const searchFormToggle = document.querySelectorAll( '.search-form-toggle' );
	const searchFormTextInputs = document.querySelectorAll( '.search-form-text' );

	// Functions
	const toggleSearchForm = ( searchFormInput ) => {
		body.classList.toggle( 'search-form-open' );
		if ( searchFormInput && body.classList.contains( 'search-form-open' ) ) {
			searchFormInput.focus();
		}
	};

	const syncTextInputs = () => {
		searchFormTextInputs.forEach( ( searchFormTextInput ) => {
			searchFormTextInput.addEventListener( 'keyup', () => {
				searchFormTextInputs.forEach( ( otherSearchFormTextInput ) => {
					otherSearchFormTextInput.value = searchFormTextInput.value;
				} );
			} );
		} );
	};
	syncTextInputs();

	// Events
	if ( searchFormToggle ) {
		searchFormToggle.forEach( ( toggle ) => {
			const searchFormInput = toggle.nextElementSibling.querySelector( '.search-form-text' );
			toggle.addEventListener( 'click', () => {
				toggleSearchForm( searchFormInput );
			} );
		} );
	}
};

export default searchForm;
