const clientMap = () => {
	// Selectors
	const clientMap = document.querySelector( '.client-map' );
	const svg = clientMap && clientMap.querySelector( 'svg' ) || '';
	const introTextBox = document.getElementById( 'content-mobile-content' );
	const sections = svg && svg.querySelectorAll( 'g[id]' ) || '';
	const contentBoxes = document.querySelectorAll( '.content-box-is-hidden' );
	const col = document.querySelector( '.animate-height .content-box:first-of-type' );
	const colHeight = col && col.clientHeight || '';

	if( ! clientMap || ! svg || ! sections || ! contentBoxes ) {
		return null;
	}

	sections.forEach( ( section ) => {
		section.addEventListener( 'mouseenter', () => {
			introTextBox.classList.add( 'content-box-is-hidden' );
			const target = document.getElementById( `content-${section.id}` );
			if ( target ) {
				contentBoxes.forEach( ( contentBox ) => {
					contentBox.classList.add( 'content-box-is-hidden' );
					target.classList.remove( 'content-box-is-hidden');
				} );
				//animateHeight( target );
			}
		} );
		section.addEventListener( 'mouseleave', (e) => {
			contentBoxes.forEach( ( contentBox ) => {
				contentBox.classList.add( 'content-box-is-hidden' );
			} );
			//animateHeight( null );
			introTextBox.classList.remove( 'content-box-is-hidden' );
		} );
	} );

	// const animateHeight = ( target ) => {
	// 	if ( col ) {
	// 		if ( target ) {
	// 			const targetHeight = target.clientHeight;
	// 			col.parentElement.style.height = targetHeight + colHeight + 60 + 'px';
	// 		} else {
	// 			col.parentElement.style.height = colHeight + 'px';
	// 		}
	// 	}
	// }

};

export default clientMap;
