import isTouchDevice from 'is-touch-device';
import Hammer from 'hammerjs';
import ScrollReveal from 'scrollreveal';

const gridItem = () => {
	// Elements
	const gridOuter = document.querySelector( '.grid-outer' );

	if ( ! gridOuter ) {
		return;
	}

	const gridItems = gridOuter.querySelectorAll( '.grid-item' );

	if ( gridItems.length < 1 ) {
		return;
	}

	const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

	// Functions
	if ( isTouchDevice() && gridItems ) {
		gridItems.forEach( ( item ) => {
			const hammer = new Hammer( item );
			hammer.on( 'tap', () => {
				item.classList.toggle( 'hover' );
			} );
		} );
	}

	if ( isIE11 ) {
		gridItems.forEach( ( item ) => {
			item.addEventListener( 'mouseenter', function() {
				item.classList.add( 'hover' );
			} );

			item.addEventListener( 'mouseleave', function() {
				item.classList.remove( 'hover' );
			} );
		} );
	}

	ScrollReveal().reveal( gridItems, {
		delay: 250,
		cleanup: true,
		distance: '20%',
		duration: 500,
		mobile: false,
		interval: 150,
		origin: 'bottom',
		easing: 'ease-in-out',
	} );
};

export default gridItem;
