const projectsFilter = () => {
	// Selectors
	const projectsFilter = document.querySelector( '.projects-filter' );
	const topLevelItems = document.querySelectorAll( '.projects-filter .top-level li' );
	const subLevelItems = document.querySelectorAll( '.projects-filter .sub-menu li' );
	const applyButton = document.querySelector( '.projects-filter .button' );
	const body = document.getElementsByTagName( 'body' )[ 0 ];

	if ( ! projectsFilter ) {
		return;
	}

	// Functions
	const handleClick = ( topLevelItem ) => {
			if ( body.classList.contains( 'is-fixed' ) ) {
				body.classList.remove( 'is-fixed' );
			} else {
				body.classList.add( 'is-fixed' );
			}

			if ( topLevelItem ) {
				topLevelItems.forEach( ( item ) => {
					if ( item !== topLevelItem ) {
						item.classList.remove( 'hover' );
					}
				} );
				topLevelItem.classList.toggle( 'hover' );
			}
	};

	const buildFilterUrl = () => {
		let url;
		const sectorActiveItems = document.querySelectorAll( '.projects-filter #sectors-dropdown li.active' );
		const expertiseActiveItems = document.querySelectorAll( '.projects-filter #expertise-types-dropdown li.active' );

		if ( sectorActiveItems.length > 0 || expertiseActiveItems.length > 0 ) {
			url = '?';

			if ( sectorActiveItems.length > 0 ) {
				url += 'project-sectors=';

				sectorActiveItems.forEach( ( item, index ) => {
					url += item.getAttribute( 'data-term' );
					if ( index !== sectorActiveItems.length - 1 ) {
						url += '+'
					}
				} );
			}

			if ( expertiseActiveItems.length > 0 ) {
				url += '&project-expertise-types=';

				expertiseActiveItems.forEach( ( item, index ) => {
					url += item.getAttribute( 'data-term' );
					if ( index !== expertiseActiveItems.length - 1 ) {
						url += '+'
					}
				} );
			}
		}

		return url;
	};

	const handleSubLevelClick = ( subLevelItem ) => {
		subLevelItem.classList.toggle( 'active' );
	};

	const handleApply = () => {
		const url = buildFilterUrl();

		if ( undefined !== url ) {
			window.location.replace( url );
		}
	};

	// Event listeners
	//applyButton.addEventListener( 'click', handleApply );

	topLevelItems.forEach( ( topLevelItem ) => {
		topLevelItem.addEventListener( 'click', () => handleClick( topLevelItem ) );
	} );

	subLevelItems.forEach( ( subLevelItem ) => {
		subLevelItem.addEventListener( 'click', () => handleSubLevelClick( subLevelItem ) );
	} );

};

export default projectsFilter;
