import { tns } from 'tiny-slider/src/tiny-slider.module';

const borderedSlider = () => {
	// Selectors
	const borderedSliderModules = document.querySelectorAll( '.bordered-slider' );

	// Functions
	const initBorderedSlider = ( borderedSlider ) => {
		var slider = tns( {
			autoplay: false,
			autoplayButtonOutput: false,
			autoplayTimeout: 7000,
			container: borderedSlider.querySelector( '.bordered-slider-slides' ),
			controls: false,
			items: 1,
			nav: true,
			navContainer: borderedSlider.querySelector( '.nav-dots' ),
			speed: 1000,
			animateIn: 'fadeInDown',
			animateOut: 'fadeOutDown',
			animateDelay: 500,
			other: {}
		} );
	};

	borderedSliderModules.forEach( borderedSlider => { initBorderedSlider( borderedSlider ) } );
};

export default borderedSlider;
