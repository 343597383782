const primaryNavMobile = () => {
	// Selectors
	const primaryNav = document.querySelector( '.primary-nav-mobile' );
	const itemsWithChildren = primaryNav.querySelectorAll( '.menu-item-has-children > a' );

	if ( ! itemsWithChildren ) {
		return;
	}

	// Functions
	const appendSpans = () => {
		itemsWithChildren.forEach( ( item ) => {
			const span = document.createElement( 'span' );

			item.appendChild( span );

			span.addEventListener( 'click', () => {
				item.parentElement.classList.toggle( 'sub-menu-is-open' );
				item.parentElement.querySelector( '.sub-menu' ).classList.toggle( 'is-open' );
			} );
		} );
	};

	// Events
	itemsWithChildren.forEach( ( item ) => {
		item.addEventListener( 'click', ( event ) => {
			if ( 'SPAN' === event.target.tagName ) {
				event.preventDefault();
			}
		} );
	} );

	// Init
	appendSpans();
};

const primaryNavDesktop = () => {
	// Selectors
	const primaryNav = document.querySelector( '.primary-nav-desktop' );
	const itemsWithChildren = primaryNav.querySelectorAll( '.menu-item-has-children' );
	const headers = document.querySelectorAll( '.header' );
	const windowWidth = window.outerWidth;

	let headerHeight;

	if( windowWidth > 992 ) {
		headerHeight = headers.length > 1 ? headers['1'].clientHeight : 115;
	}

	headers.forEach( ( header ) => {
		header.style.height = headerHeight + 'px';
	} );

	window.addEventListener( 'resize', () => {
		const windowWidth = window.outerWidth;

		if( windowWidth > 992 ) {
			headerHeight = 115;
		} else {
			headerHeight = 84;
		}

		headers.forEach( ( header ) => {
			header.style.height = headerHeight + 'px';
		} );
	}, true );

	if ( ! itemsWithChildren ) {
		return;
	}

	itemsWithChildren.forEach( ( item ) => {
		const subMenu = item.querySelector( '.sub-menu' );
		const subMenuHeight = subMenu.clientHeight;

		if ( subMenu ) {
			item.addEventListener( 'mouseenter', () => {
				const subMenuItems = subMenu.querySelectorAll( 'li' );

				headers.forEach( ( header ) => {
					header.style.height = headerHeight + subMenuHeight + 'px';
					header.classList.add( 'is-expanded' );
				} );
				subMenu.classList.add( 'is-open' );

				subMenuItems.forEach( ( subMenuItem ) => {
					subMenuItem.classList.remove( 'is-shown' );
				} );

				subMenuItems.forEach( ( subMenuItem, index ) => {
					setTimeout( () => {
						subMenuItem.classList.add( 'is-shown' );
					}, 100 * index );
				} );
			} );
			item.addEventListener( 'mouseleave', () => {
				headers.forEach( ( header ) => {
					header.style.height = headerHeight + 'px';
					header.classList.remove( 'is-expanded' );
				} );
				subMenu.classList.remove( 'is-open' );
			} );
		}
	} );
};

const primaryNav = () => {
	primaryNavMobile();
	primaryNavDesktop();
};

// Init
export default primaryNav;

