import 'nodelist-foreach-polyfill';

const publicationsSlider = () => {
	// Selectors
	//const publicationsSlider = document.querySelector( '.publications-slider' );
	const publicationsNav = document.querySelector( '.publications-nav' );
	const publications = document.querySelectorAll( '.publication-item' );

	if( ! publicationsNav || ! publications ) {
		return;
	}

	publicationsNav.querySelectorAll( 'a' ).forEach( ( link ) => {
		link.addEventListener( 'click', ( event ) => {
			event.preventDefault();
			const target = document.querySelector( '' + link.getAttribute( 'data-anchor' ) + '' );
			publications.forEach( ( publication ) => {
				publication.classList.add( 'screens-screen-blocks-hidden' );
			} );
			target.classList.remove( 'screens-screen-blocks-hidden' );
			publicationsNav.querySelectorAll( 'a' ).forEach( ( item ) => {
				item.classList.remove( 'active' )
			} );
			link.classList.add( 'active' );
		} );
	} );

};

export default publicationsSlider;
