import { tns } from 'tiny-slider/src/tiny-slider.module';

const fullWidthSlider = () => {
	// Selectors
	const borderedSliderModules = document.querySelectorAll( '.full-width-slider' );

	// Functions
	const initFullWidthSlider = ( borderedSlider ) => {
		var slider = tns( {
			autoplay: false,
			autoplayButtonOutput: false,
			autoplayTimeout: 7000,
			container: borderedSlider.querySelector( '.full-width-slider-slides' ),
			controls: false,
			items: 1,
			nav: true,
			navContainer: borderedSlider.querySelector( '.nav-dots' ),
			speed: 1000,
			animateIn: 'fadeInDown',
			animateOut: 'fadeOutDown',
			animateDelay: 500,
			other: {}
		} );
	};

	borderedSliderModules.forEach( borderedSlider => { initFullWidthSlider( borderedSlider ) } );
};

export default fullWidthSlider;
