/**
 * External dependencies
 */
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'nodelist-foreach-polyfill';
import 'es6-shim';
import smoothscroll from 'smoothscroll-polyfill';
import Turbolinks from 'turbolinks';

smoothscroll.polyfill();

/**
 * Internal dependencies
 */
import header from '../../templates/global/header/header';
import searchForm from '../../templates/global/search-form/search-form';
import primaryNav from '../../templates/global/primary-nav/primary-nav';
import splash from '../../templates/module/splash/splash';
import banner from '../../templates/module/banner/banner';
import screens from '../../templates/module/screens/screens';
import clientMap from '../../templates/module/client-map/client-map';
import quoteSlider from '../../templates/module/quote-slider/quote-slider';
import borderedSlider from '../../templates/module/bordered-slider/bordered-slider';
import fullWidthSlider from '../../templates/module/full-width-slider/full-width-slider';
import locationMap from '../../templates/module/location-map/location-map';
import publicationSlider from '../../templates/module/publications-slider/publications-slider';
import projectsFilter from '../../templates/module/projects-filter/projects-filter';
import gridItem from '../../templates/module/grid-item/grid-item';
import logoSlider from '../../templates/module/logo-slider/logo-slider';

/**
 * Boot
 */
Turbolinks.start();

document.addEventListener( 'turbolinks:load', function() {
	const body = document.getElementsByTagName( 'body' )[ '0' ];
	body.classList.add( 'fadeIn' );

	const links = document.links;
	for ( let i = 0, linksLength = links.length; i < linksLength; i++) {
		if ( links[i].hostname !== window.location.hostname || links[i].href.includes('.pdf') ) {
			links[i].target = '_blank';
		}
	}

	header();
	searchForm();
	primaryNav();
	splash();
	banner();
	clientMap();
	quoteSlider();
	borderedSlider();
	fullWidthSlider();
	locationMap();
	publicationSlider();
	projectsFilter();
	gridItem();
	logoSlider();
	screens();
} );

document.addEventListener( 'turbolinks:before-visit', function() {
	const body = document.getElementsByTagName( 'body' )[ '0' ];
	body.classList.add( 'fadeOut' );
} );
