const locationMap = () => {
	// Selectors
	const locationMap = document.querySelector( '.location-map' );
	const mapElement = locationMap && locationMap.querySelector( '.location-map-map' );

	if( ! locationMap ) {
		return null;
	}

	// Functions
	const initMap = ( mapElement ) => {
		const markerElement = mapElement.querySelector( '.location-map-marker' );
		const latLng = new google.maps.LatLng( markerElement.getAttribute( 'data-lat' ), markerElement.getAttribute( 'data-lng' ) );
		const mapArgs = {
			zoom: 14,
			center: latLng,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
		};
		var map = new google.maps.Map( mapElement, mapArgs );
		const markerArgs = {
			position: latLng,
			map: map,
		};
		var marker = new google.maps.Marker( markerArgs );
	};

	// Init
	initMap( mapElement );
	google.maps.event.trigger( mapElement, 'resize');
};

export default locationMap;
