const header = () => {
	// Selectors
	const body = document.getElementsByTagName( 'body' )[ 0 ];
	const menuToggles = document.querySelectorAll( '.header-menu-toggle' );
	const header = document.querySelector( '.header:not(.header-dropdown)' );
	const dropdown = document.querySelector( '.header-dropdown' );

	// Functions
	const toggleMenu = () => {
		body.classList.toggle( 'menu-open' );
	};

	// Events
	if ( menuToggles ) {
		menuToggles.forEach( ( toggle ) => {
			toggle.addEventListener( 'click', () => {
				toggleMenu();
			} );
		} );
	}
	if ( dropdown )  {
		header.addEventListener( 'mouseenter', () => {
			dropdown.classList.add( 'is-shown' );
		} );

		dropdown.addEventListener( 'mouseleave', () => {
			dropdown.classList.remove( 'is-shown' );
		} );
	}

	let lastScrollTop = 0;

	jQuery(window).scroll(function(e) {
	    let st = jQuery(this).scrollTop();
	   
	    if (st > lastScrollTop) {
	       	if (! jQuery('.animated-header').hasClass('slide-out') && st > 115) {
	       		jQuery('.animated-header').addClass('slide-out');
	       	}
	    } else {
	    	if (jQuery('.animated-header').hasClass('slide-out')) {
	       		jQuery('.animated-header').removeClass('slide-out');
	       	}
	    }
	   
	    lastScrollTop = st;
	});
};

export default header;
